body, html {
  margin: 0;
  padding: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
  color: rgb(0, 0, 0);
  font-family: Arial, sans-serif;
  display: flex;
  justify-content: center;
  align-items: center;
}

.App {
  position: relative;
  text-align: center;
}

.content {
  position: relative;
}

.content h1 {
  margin-bottom: 20px;
}

.content h2 {
  cursor: pointer; /* Indicate that the h2 element is clickable */
}

.content ul {
  list-style: none;
  padding: 0;
}

.content ul li {
  margin-bottom: 10px;
}

.content ul li button {
  background-color: #00f;
  color: white;
  border: none;
  padding: 10px 20px;
  cursor: pointer;
  font-size: 16px;
  border-radius: 5px;
}

.content ul li button:hover {
  background-color: #0056b3;
}


/* Media Queries for Mobile Devices */
@media (max-width: 600px) {
  .content h1 {
    font-size: 24px;
  }

  .content h2 {
    font-size: 13px;
  }

  .content ul li button {
    font-size: 14px;
    padding: 8px 16px;
  }
}