#overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: black; /* Fully black */
  pointer-events: none; /* Disable pointer events for the overlay */
}

#scope {
  position: fixed;
  width: 150px;
  height: 150px;
  border-radius: 50%;
  border: 2px solid white; /* Optional: to make the scope visible */
  pointer-events: none; /* Disable pointer events for the scope itself */
  background: transparent;
  display: flex;
  justify-content: center;
  align-items: center;
}

.crosshair {
  position: absolute;
  background: black; /* Make the crosshair marks black */
}

.crosshair.horizontal {
  width: 100%;
  height: 2px;
}

.crosshair.vertical {
  width: 2px;
  height: 100%;
}

#clickable-layer {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  pointer-events: auto; /* Enable pointer events for the clickable layer */
  background: transparent; /* Make the layer invisible */
}